import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";

export const toggleNavDropdown = () => {
    const toggleNavDropdownButton = document.getElementById('headerSelectNav') as HTMLButtonElement | null;
    if (toggleNavDropdownButton) {
        toggleNavDropdownButton.classList.toggle('active');
    }
}

export const toggleParentBorder = () => {
    const parentBorder = document.getElementById('parentBorder') as HTMLElement | null;
    if (parentBorder) {
        parentBorder.style.borderBottomRightRadius = "0px";
    }
}
export const toggleParentBorderOff = () => {
    const parentBorder = document.getElementById('parentBorder') as HTMLElement | null;
    if (parentBorder) {
        parentBorder.style.borderBottomRightRadius = "8px";
    }
}

export const navContainer = () => {
    return (
        <div className={styles.headerSelectNav} >
            <div className={`${styles.headerSelectedNav} first-class`}
                onClick={() => toggleNavDropdown()}
                id="headerSelectNav"
            >
                Staff Report Writer
                <div className={`${styles.headerSelectedNavDropDown} second-class`} style={{ display: "none" }}>
                    <ul className={styles.dialogItems}>
                        <li className={styles.promptClickerTitle}>
                            Select Madison AI Assistant
                        </li>
                        <li className="promptClicker">
                            <a href="https://reno.madisonai.com/">Citywide Knowledge Base</a>
                            <div className={styles.dialogItemsCheck}>
                                <input type="checkbox" className="checkbox" />
                                <label htmlFor="myCheckbox">
                                    <span className={styles.checkboxIcon}></span>
                                </label>
                            </div>
                            <div className={styles.navDescription}>Minutes, Staff Reports, Code, NRS</div>
                        </li>

                        <li className="promptClicker">
                            <a href="https://reno-pc.madisonai.com/">Planning Knowledge Base</a>
                            <div className={styles.dialogItemsCheck}>
                                <input type="checkbox" className="checkbox" />
                                <label htmlFor="myCheckbox">
                                    <span className={styles.checkboxIcon}></span>
                                </label>
                            </div>
                        </li>

                        <li className="promptClicker">
                            <a href="https://os-staff-reporting-cor-bus-lic-det.azurewebsites.net/">Business Licensing Report Writer</a>
                            <div className={styles.dialogItemsCheck}>
                                <input type="checkbox" className="checkbox" />
                                <label htmlFor="myCheckbox">
                                    <span className={styles.checkboxIcon}></span>
                                </label>
                            </div>
                        </li>
                        <hr />
                        <li className={styles.reportClicker} onMouseOver={() => toggleParentBorder()} onMouseOut={() => toggleParentBorderOff()}>
                            <a className={styles.noPadding} href="#">Staff Report Writer
                                <img style={{ position: "relative", top: "4px", float: "right", right: "15px" }} src="https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/icon-arrow-right-black.svg"></img>
                            </a>
                            <div className={styles.navFlyout} onMouseOver={() => toggleParentBorder()} onMouseOut={() => toggleParentBorderOff()}>
                                <ul>
                                    <li className="promptClicker notSelected">
                                        <a href="https://reno-sr.madisonai.com/">Citywide Staff Report</a>
                                        <div className={styles.dialogItemsCheck}>
                                            <input type="checkbox" className="checkbox" />
                                            <label htmlFor="myCheckbox">
                                                <span className={styles.checkboxIcon}></span>
                                            </label>
                                        </div>
                                    </li>
                                    <li className="promptClicker selected">
                                        <a href="https://reno-pc-sr.madisonai.com/">Planning Staff Report</a>
                                        <div className={styles.dialogItemsCheck}>
                                            <input type="checkbox" className="checkbox" />
                                            <label htmlFor="myCheckbox">
                                                <span className={styles.checkboxIcon}></span>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    )
}
