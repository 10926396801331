import React, { useCallback, useState } from 'react';
import { IconButton, Stack, TextField } from "@fluentui/react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styles from "./Chat.module.css";
import { configuration } from "../../constants/configuration";

import {
    firstPrompt
} from "./AutoPrompt";

import {
    reportToggler
} from "./functions";

import { FileUploadModal } from "../../components/QuestionInput/FileUploadModal";

export const toggleContainer = () => {
    return (
        <Stack>
            <div className={`${styles.toggler}`} tabIndex={0}>
                <a href="https://os-staff-reporting.azurewebsites.net/" style={{ flex: 1, textAlign: 'right', textDecoration: "none", color: "#000" }}>Research Assistant</a>
                <a href="https://os-staff-reporting.azurewebsites.net/">
                    <img
                        style={{ flex: 1, maxWidth: '60px' }}
                        src='https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/toggler.svg'
                        className={styles.togglerIcon}
                        aria-hidden="true" />
                </a>
                <a href="https://washoe-staff-report-general.azurewebsites.net/" style={{ flex: 1, textDecoration: "none", fontWeight: "bold", color: "#000" }}>Write a Staff Report</a>
                {/* <span
                    className={`${styles.togglerReports} togglerDropDownController`}
                    style={{ flex: 1, textDecoration: "none", fontWeight: "bold", color: "#000" }}
                    onClick={() => reportToggler()} // Wrap the onClick event handler in curly braces and make it a function expression
                >
                    Write a Report
                </span>
                <div className={`${styles.togglerDropDown} togglerDropDownContent`}>
                    <ul>
                        <li><a href="https://washoe-staff-report-general.azurewebsites.net/">General Report</a></li>
                        <li><a href="#">BID Report</a></li>
                        <li><a href="#">Grant Report</a></li>
                    </ul>
                </div> */}
            </div>
        </Stack>
    )
}

export const reportNavContainer = () => {
    return (
        <Stack>
            <div className={styles.chatMessagePromptWrapper}>
                <div className={styles.chatMessagePromptWrapper}>
                    <a href="https://washoe-staff-report-general.azurewebsites.net/" className={`${styles.chatMessagePromptMessageBlock} topicToggle toggleActive`}
                        style={{ width: "230px", margin: "8px", padding: "15px 25px" }}>
                        <div className={styles.chatMessagePromptMessage}>
                            <h3 className={styles.buttonReportBlockSmall}>Write a Staff Report</h3>
                            <p style={{marginBottom: "0px"}}>Write a report to preparation for your next council meeting.</p>
                        </div>
                    </a>
                    <a href="https://madison-washoe-county-bid-reports.azurewebsites.net/" className={`${styles.chatMessagePromptMessageBlock} topicToggle`}
                        style={{ width: "230px", margin: "8px", padding: "15px 25px" }}>
                        <div className={styles.chatMessagePromptMessage}>
                            <h3 className={styles.buttonReportBlockSmall}>Write a BID Staff Report</h3>
                            <p style={{marginBottom: "0px"}}>Write a staff report for the Business Improvement District.</p>
                        </div>
                    </a>
                    <a href="https://madison-washoe-county-grant-reports.azurewebsites.net/" className={`${styles.chatMessagePromptMessageBlock} topicToggle`}
                        style={{ width: "230px", margin: "8px", padding: "15px 25px" }}>
                        <div className={styles.chatMessagePromptMessage}>
                            <h3 className={styles.buttonReportBlockSmall}>Write a Grant Staff Report</h3>
                            <p style={{marginBottom: "0px"}}>Write a staff report for grant funding request or approval.</p>
                        </div>
                    </a>
                </div>
            </div>
        </Stack >
    )
}

export const promptWrapperContainer = () => {
    const [displayUploadModal, setDisplayUploadModal] = useState(false);
    return (
        <Stack>
            <div className={styles.chatMessagePromptWrapper}>
                <div className={styles.chatMessagePromptMessageBlock} id="researchTopicButton">
                    <div className={styles.chatMessagePromptMessageLeft}>
                        <h3 className={styles.buttonReportBlock}>Draft a Staff Report for Your Next Meeting</h3>
                        <p>Write a report in preparation for your next commission meeting.</p>
                        <div className={styles.buttonReport} onClick={() => firstPrompt()}>Draft My Report</div>
                    </div>
                    <div className={styles.chatMessagePromptMessageRight}>
                        <textarea rows={6} placeholder="Enter your report topic. Be specific and detailed." id="staffReportTextArea" className={styles.fakeTextArea}></textarea>
                        <IconButton
                            iconProps={{ iconName: 'Attach', className: styles.whiteIconLanding }}
                            id="uploadButton"
                            styles={{
                                icon: { fontSize: 24 },
                                root: {
                                    backgroundColor: '#006d9b',
                                    height: '50px',
                                    width: '50px',
                                    marginLeft: '10px',
                                    marginTop: '10px',
                                    borderRadius: '8px',
                                    color: 'white !important',
                                    position: 'absolute',
                                    left:"0px",
                                    bottom:"15px"
                                },
                                rootHovered: { backgroundColor: '#0eb6e3' },
                                rootPressed: { backgroundColor: '#0eb6e3' }
                            }}
                            ariaLabel="Upload files"
                            onClick={() => setDisplayUploadModal(true)}
                        />
                        <FileUploadModal isOpen={displayUploadModal} setOpen={(val) => setDisplayUploadModal(val)} />
                    </div>
                    <div>
                        <span data-tooltip-place="bottom" data-tooltip-id="sample-tooltip" data-tooltip-html={configuration.tooltip.sample_prompt} className={styles.hoverLink}>Sample Prompt</span>
                        <ReactTooltip id="sample-tooltip" className="tool__tips" style={{ maxWidth: '350px', whiteSpace: 'normal' }}/>
                        {/* <span className={styles.chatEmptyStateSubtitle}>{configuration.header.description}</span> */}
                    </div>
                </div>
            </div>
        </Stack>
    )
}

export const infoWrapperContainer = () => {
    return (
        <Stack>
            <div className={styles.infoContainer} tabIndex={0}>
                <div className={styles.infoColumn}>
                    <h4 className={styles.infoColumnHeader}><i className={styles.infoColumnIconSource}></i> What used as your data source?</h4>
                    <p>Madison has indexed your <strong>Board of County Commissioners</strong> file set, including board packets, agendas, meeting minutes, previous board action, codes, and statues.</p>
                    <p>Your database includes a library of ~3,884 files.</p>
                </div>
                <div className={styles.infoColumn}>
                    <h4 className={styles.infoColumnHeader}><i className={styles.infoColumnIconCreate}></i> What can Madison create?</h4>
                    <p>I’m an AI assistant to help research previous board action or prepare your next staff report. I can help you:</p>
                    <ul>
                        <li>Research and find all files related to a topic.</li>
                        <li>Summarize previous board action.</li>
                        <li>Draft a staff report to include motions, bid approvals, or grant approvals. </li>
                    </ul>
                </div>
            </div>
        </Stack>
    )
}

export const landingPageBrandingWrapper = () => {
    return (
      <>
        {configuration.header.visible && (
          <Stack className={styles.chatEmptyState}>
            <img
              src={configuration.header.logo}
              className={styles.chatIcon}
              aria-hidden="true"
            />
            {/* <div className={styles.chatEmptyStateTitle}>
              {configuration.header.title}
            </div> */}
            <div>
              <a data-tooltip-place="bottom" data-tooltip-id="info-tooltip" data-tooltip-html={configuration.tooltip.tooltip_info} target="_blank" href={configuration.sharepoint.information_link} className={styles.chatEmptyStateSubtitle}><img src="https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/icon-info.svg"></img>{configuration.header.subtitle}</a>
              <ReactTooltip id="info-tooltip" className="tool__tips"/>
              {/* <span className={styles.chatEmptyStateSubtitle}>{configuration.header.description}</span> */}
            </div>
          </Stack>
        )}
      </>
    );
  };

