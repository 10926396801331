let count = 0; // Declare the variable 'count'
let savedPrompt = '';
export function isThisLoading(isLoading: boolean) {
    // console.log('isLoading: ', isLoading, ` test ` + `${count}`)
    count++;
    let promptCount = count
    if (count >= 1 && count <= 9) {
        autoPrompts(promptCount, savedPrompt);
    } else if (count == 10) {
        generateReport(promptCount, savedPrompt);
    }

}

const updatePromptTextArea = (textContent: any) => {
    console.log('f: ', count, ' text:', textContent)
    const promptTextArea = document.getElementById('promptTextArea') as HTMLTextAreaElement;
    if (promptTextArea) {
        promptTextArea.value = '';
        promptTextArea.value = textContent; // Include inputText value
    }
}

const triggerPromptButtonClick = (event?: Event) => {
    // Programmatically trigger button click
    const promptEnterButton = document.getElementById('promptEnter') as HTMLButtonElement | null;
    if (promptEnterButton) {
        promptEnterButton.click();
        if (event) {
            event.stopPropagation();
        }
    }
}

export function firstPrompt() {
    console.log('f: ', count);

    const loadingImage = document.getElementById('loadingImage') as HTMLElement;
    loadingImage.style.display = 'block';

    const textArea = document.getElementById('staffReportTextArea') as HTMLTextAreaElement;
    
    savedPrompt = textArea.value;
    // SUMMARY
    const textContent = `Write a property or project summary of the ${savedPrompt} Include location, description of the request and reason for the request, and reference the land use designation it falls within. Include why the application is required. Your last sentence should indicate staff’s recommendation to the Planning Commission. Include one sentence about the key issues to be addressed to comply with the request. Write at least one paragraph and up to three.`;

    // Usage with delay:
    setTimeout(() => {
        updatePromptTextArea(textContent);
    }, 50); // 50-millisecond delay

    // Usage with delay:
    setTimeout(() => {
        triggerPromptButtonClick();
        const questionInputContainer = document.querySelector('#chatInputWrapper');
        if (questionInputContainer) {
            questionInputContainer.classList.add('active');
        }

    }, 250); // 50-millisecond delay

}

export function autoPrompts(promptCount: number, mySavedPrompt: any) {
    // console.log('f: ', count);
    let textContent = '';

    const textArea = document.getElementById('staffReportTextArea') as HTMLTextAreaElement;

    // Background
    if (promptCount == 1) {
        textContent = `Write a historical background on the ${mySavedPrompt}. Only include information such as original construction date, improvements, entitlement history, and action by Planning Commission specific to this property. Write at least one paragraph and up to three. Do not restate this prompt as an initial sentence or heading.`;
    }
    // Analysis
    else if (promptCount == 2) {
        textContent = `Based on the issues identified in the project summary, write a detailed analysis of the ${mySavedPrompt}. The analysis should include a number of sections that at least mirror the key issues identified in the Summary section, though additional sections are allowed. The below headers are suggestions but may be altered or added to for the specific analysis. Each section must be at least one paragraph. The analysis must point out the issue and the recommendation to remedy to come in compliance with the zoning codes.\n Include the following sections: Building and Site Design, Land Use Compatibility, Overall Development Plan and Development Standards, Compatibility with Surrounding Uses, Code Compliance, Conformance with the Master Plan, Public Services, Flood Plain Management, Public Safety, Drainage and Hydrology, Traffic, Access, and Circulation, and Public and Stakeholder Engagement. Do not restate this prompt as an initial sentence or heading.`
    }
    // Request Description 
    else if (promptCount == 3) {
        textContent = `Write a request statement that clearly outline the request(s) about the ${mySavedPrompt}, including any specific permits, maps, deviations, or compliance variations being sought. Start with the type of request, bolded. Your response should only be one or two sentences that summarizes the nature of the request. Use bullet points. Do not restate this prompt as an initial sentence or heading.`
    }
    // Proposed Motion
    else if (promptCount == 4) {
        textContent = `Write a motion based on compliance with applicable findings for the ${mySavedPrompt}. Use this exact structure:  Based upon compliance with the applicable findings, I move to approve the {Application Type}, subject to conditions listed in the staff report. Do not restate this prompt as an initial sentence or heading.`;
    }
    // Property or Project Summary 
    else if (promptCount == 5) {
        textContent = `Write a property or project summary of the ${mySavedPrompt}. Your summary must include location, description of the request and reason for the request, and reference the land use designation it falls within. Your summary must also include why the application is required. Your last sentence should indicate staff’s recommendation to the Planning Commission. Include one sentence about the key issues to be addressed to comply with the request. Write at least one paragraph and up to three. Do not restate this prompt as an initial sentence or heading.`;
    }
    // Property of Project Background
    else if (promptCount == 6) {
        textContent = `Write a historical background on the ${mySavedPrompt}. Only include information such as original construction date, improvements, entitlement history, and action by Planning Commission specific to this property. Write at least one paragraph and up to three. Do not restate this prompt as an initial sentence or heading.`;
    }
    // Detailed Property or Project Analysis
    else if (promptCount == 7) {
        textContent = `Based on the issues identified in the project summary, write a detailed analysis of the ${mySavedPrompt}. The analysis should include a number of sections that at least mirror the key issues identified in the Summary section, though additional sections are allowed. The below headers are suggestions but may be altered or added to for the specific analysis. Each section must be at least one paragraph. The analysis must point out the issue and the recommendation to remedy to come in compliance with the zoning codes.\n Include the following sections: Building and Site Design, Compatibility with Surrounding Uses, Code Compliance, Public Safety, Drainage and Hydrology, Traffic, Access, and Circulation. Do not restate this prompt as an initial sentence or heading.`;
    }
    // Master Plan Conformance
    else if (promptCount == 8) {
        textContent = `Write an analysis about this ${mySavedPrompt} conformance to the Master Plan.\nUse this exact structure: The subject site has a Master Plan land use designation of {Master Plan Designation} and is located within {List other relevant structure plan designations.} per the Structure Plan Framework of the Reno Master Plan. As proposed, and with the recommended conditions, the project is in conformance with the {Master Plan Designation.} Master Plan land use designation and the following applicable Master Plan goals and policies. Do not restate this prompt as an initial sentence or heading. `;
    }
    // Master Plan Conformance
    else if (promptCount == 9) {
        textContent = `Based on the data you have access to, gather any information about public opinion related to this ${mySavedPrompt} specifically. Write a summary.\nUse this exact structure: Agency comments that were received were integrated into this staff report (Exhibit [XX]). The applicant presented the project to the {Ward No} Neighborhood Advisory Board (NAB) on [Date Long Form]. Several concerns were raised related to [List issues raised by NAB and community at the meeting]. A courtesy notice was sent out to surrounding property owners upon initial submission of the project and [detail correspondence received, number in support, opposition, and concern, and list concerns] (Exhibit [XX]). [How are these issues addressed or otherwise not relevant to the review of the application]. Any future comments will be forwarded to the Planning Commission as they are received.  Do not restate this prompt as an initial sentence or heading.`;
    }

    // console.log(mySavedPrompt)

    // Usage with delay:
    setTimeout(() => {
        updatePromptTextArea(textContent);
    }, 50); // 50-millisecond delay

    // Usage with delay:
    setTimeout(() => {
        triggerPromptButtonClick();
    }, 1000); // 50-millisecond delay

}

export const generateReport = (promptCount: number, mySavedPrompt: any) => {
    const selectedDivs = document.querySelectorAll('.content');
    const contentData: Record<string, string> = {};
    const targetDiv = document.getElementById('chatMessageStream') as HTMLElement;

    const superscriptPattern = /<sup\b[^>]*>([\s\S]*?)<\/sup>/gi;

    function removeHTMLBetweenSuperscript(selectedDivsText: string): string {
        const superscriptPattern = /<sup\b[^>]*>([\s\S]*?)<\/sup>/gi;
        return selectedDivsText.replace(superscriptPattern, '');
    }

    selectedDivs.forEach((div, index) => {
        const selectedDivs = div as HTMLElement;
        const selectedDivsHtml = selectedDivs.innerHTML;
        if (selectedDivsHtml !== null) {
            const cleanedText = removeHTMLBetweenSuperscript(selectedDivsHtml);
            contentData[index] = cleanedText.replaceAll('<p>', '<p style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<td>', '<td style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<li>', '<li style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;"><p style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt; margin: 0;">').replaceAll('</li>', '</p></li>').replaceAll('<strong>', '<strong style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h1>', '<h1 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h2>', '<h2 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h3>', '<h3 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h4>', '<h4 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">');
        }
    });

    // Usage with delay:
    setTimeout(() => {
        const StaffReportHTML = `
    <div class="chatMessageGpt">
        <div class="chatMessageId">
            <div class="answerContainer static-class selected" tabindex="0" id="printArea">
                <div class="answerText content">

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Date</strong>:</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To</strong>: Reno City Planning Commission</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Subject</strong>: ${mySavedPrompt}</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">From</strong>: </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Ward #</strong>: </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Case No.</strong>: </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Applicant</strong>: </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">APN</strong>: </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Request</strong>: </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Location</strong>: See Case Maps (Exhibit A) </p>

                    <hr />

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Recommended Condition of Approval </strong></p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Summary:</strong></p>${contentData[0]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Background</strong>:</p>${contentData[1]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Analysis</strong>:</p>${contentData[2]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Findings:</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">General Review Criteria</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Rezoning (Zoning Map Amendment)</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Rezoning to Specific Plan District (SPD) </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Attachments:</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Exhibit A - Case Maps</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Exhibit B - SPD Handbook</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Exhibit C - Elevations</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Exhibit D - Traffic Study</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Exhibit D - Site Plan</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Exhibit E - Agency Comments</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Exhibit F - Public Comment</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Request Description:</strong></p>${contentData[3]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Proposed Motion:</strong></p>${contentData[4]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Property or Project Summary:</strong></p>${contentData[5]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Property of Project Background:</strong></p>${contentData[6]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Detailed Property or Project Analysis:</strong></p>${contentData[7]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Master Plan Conformance:</strong></p>${contentData[8]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Public and Stakeholder Engagement:</strong></p>${contentData[9]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>




                    
                </div>
            </div>
            <div class="promptRepsonseInteractionContainer">
                <div class="promptRepsonseInteractionSaveButton" id="downloadAsWord" role="button" aria-label="Save button">Download as Word</div>
            </div>
        </div>
    </div>
    `
        const loadingImage = document.getElementById('loadingImage') as HTMLElement;
        loadingImage.style.display = 'none';
        targetDiv.insertAdjacentHTML('beforeend', StaffReportHTML);

        console.log(contentData, 'Completed!')
    }, 2000); // 50-millisecond delay

}